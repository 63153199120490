.container {
  display: flex;
  justify-content: space-between;
}

.wrapper {
  background: var(--white);
  padding: 2rem;
  border-radius: 12px;
  margin-bottom: 4rem;
  height: 70vh;
  width: 50%;
  position: relative;
  display: flex;
  flex-direction: column;
  /* min-width: 800px; */
}

.selected {
  margin-left: 2rem;
  width: 50%;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* min-width: 640px; */
}

.selectedWrapper {
  background: var(--white);
  padding: 2rem;
  border-radius: 12px;
  margin-bottom: 4rem;
  height: 62vh;
}

.selectPageSize > div {
  height: 4rem;
  width: 9rem;
  position: relative;
}

.btn {
  width: 100%;
  display: flex;
  justify-content: end;
  position: relative;
}

.accountsTableWrapper {
  height: 87%;
  overflow: auto;
  overflow-y: hidden;
}

.confirmTableWrapper {
  height: 100%;
  /* overflow: auto; */
}

.error {
  position: absolute;
  top: -2.7rem;
  right: 0;
  font-size: 1.6rem;
  color: var(--red);
}

.tabWrapper {
  position: relative;
}

.tab__alert {
  position: absolute;
  min-width: 44%;
  top: 0;
  right: 0;
}

.paginationContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 2.4rem;
  align-items: center;
}

.pagination {
  margin-bottom: 2.4rem;
}

/* Responsive */
@media (max-width: 1400px) {
  .wrapper {
    width: 56%;
  }

  .selected {
    width: 44%;
  }

  .selectedWrapper {
    padding: 30px 20px;
  }
}
