@import './libs/normalize.css';
@import './libs/reset.css';

html {
  font-size: 56.25%;
}

body {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  /* 400/500/700 */
  background: var(--body);
  color: var(--black);
  overflow-x: hidden;
}

:root {
  --body: #f4f7fe;
  --white: #ffffff;
  --black: #111a39;
  --black2: #333333;
  --black3: #1f1f1f;
  --stroke: #e9eafe;
  --stroke2: #f2f2f2;
  --stroke3: #fafafa;
  --stroke4: #e6e6e6;
  --gray: #b1b7ca;
  --gray2: #726f95;
  --gray3: #9492a5;
  --gray4: #d6d6d6;
  --gray5: #4a485e;
  --gray6: #838383;
  --primary: #2055fd;
  --primary2: #1473e6;
  --violet: #6656f5;
  --green: #00ad30;
  --green2: #27ae60;
  --green2Opcty: rgba(39, 174, 96, 0.2);
  --green3: #7cc59a;
  --green3Opcty: rgba(124, 197, 154, 0.2);
  --orange: #f2994a;
  --orangeOpcty: rgba(242, 153, 74, 0.2);
  --red: #e84c4c;
  --redOpcty: rgba(232, 76, 76, 0.2);
  --red2: #d95b5b;
  --datesOption: #e1e8fb;
}

body .notyf {
  font-size: 1.6rem;
}

.modal__control {
  height: 7rem;
}

/* AuthForm */
.auth-form-wrapper .logo {
  padding-left: 12rem;
  padding-top: 2rem;
}

.create-account-wrapper .sub-title,
.auth-form-wrapper .sub-title {
  font-size: 1.6rem;
  font-weight: 400;
  color: var(--gray2);
  margin: -2rem 0 2.5rem;
}

.auth-footer-text {
  text-align: center;
  margin: 2rem 0 0;
}

.auth-footer-text p {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: var(--primary);
  text-decoration: none;
  color: var(--gray2);
}

.auth-footer-text a {
  color: var(--primary);
  text-decoration: none;
}

.auth-footer-text a:hover {
  text-decoration: underline;
}

.auth-footer-text .term-text {
  display: inline-block;
  width: 100%;
  margin: 2.5rem 0 0;
}

.auth-footer-text .term-text a {
  color: var(--gray2);
  text-decoration: underline;
}

.as-link {
  color: var(--primary);
  cursor: pointer;
}

.as-link:hover {
  text-decoration: underline;
}

.alert-message-wrapper {
}

.alert-message {
  width: auto;
  padding: 1.1rem 3rem 1.1rem 3rem;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 1.5rem;
}

.alert-message p {
  color: var(--black);
  font-size: 1.2rem;
  margin: 0;
  line-height: 1.5;
  font-weight: 400;
}

.alert-message img {
  width: 2rem;
  height: 2rem;
}

.alert-message img,
.alert-message i {
  margin: 0 .8rem 0 0;
  font-size: 2rem;
}

.alert-message.error {
  background: #f6e3e1;
}

.alert-message.error i {
  color: #f53f3f;
}

.alert-message.success {
  background: #d3fae6;
}

.alert-message.success i {
  color: #52bd95;
}

.alert-message.warning {
  background: #ffd7b4;
}

.alert-message.warning i {
  color: #f89238;
}

.create-account-wrapper {
  /* min-height: 100vh;
  background-image: url("../assets/create-account-bg.jpg");
  background-size: cover;
  background-position: center;
  position: relative; */
}

.create-account-wrapper:after {
  content: '';
  width: 100%;
  height: 100%;
  background-color: #dddddd;
  opacity: 0.7;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.form-control {
  width: 100%;
  height: 7rem;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.9rem;
  border: 1px solid var(--gray);
  border-radius: 6px;
  outline: none;
  color: var(--gray2) !important;
  padding: 0 2.5rem;
  margin-bottom: 3rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.create-success-wrapper .close {
  color: #d7d6d8;
  font-size: 2.5rem;
  position: absolute;
  top: 1.5rem;
  right: 2rem;
  cursor: pointer;
}

.create-success-wrapper h1 {
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 3.8rem;
  margin-bottom: 2rem;
}

.create-success-wrapper p {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.8rem;
  margin: 0 0 1.5rem;

  color: #5e5d62;
}

.create-success-wrapper button {
  margin-top: 2rem;
}

.select-wrapper {
  position: relative;
}

.select-wrapper .arrow {
  position: absolute;
  right: 2.5rem;
  top: 3.5rem;
  color: #9492a5;
  font-size: 1.8rem;

  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
}

.logo {
  max-width: 2.92rem;
  padding-left: 12rem;
  padding-top: 2rem;
}

/* Responsive */
@media (max-width: 1400px) {
  .modal__control {
    height: 60px;
  }
}
