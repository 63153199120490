.customModal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: auto;
    z-index: 11;
    overflow-y: auto;
    opacity: 0;
    transition: 0.5s;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
}

.background {
    position: fixed;
    background-color: black;
    opacity: 0.65;
    z-index: -1;
    width: 100%;
    height: 100%;
}

.customModal::-webkit-scrollbar {
    width: 0;
}

.animated {
    opacity: 1;
    transition: 0.5s;
}

.container {
    position: absolute;
    top: -10rem;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 5px;
    margin-bottom: 2.8rem;
    transition: 0.5s;
    border-radius: 20px;
}

.containerAnimated {
    top: 15rem;
    transition: 0.5s;
}

.header {
    border-radius: 20px 20px 0 0;
    background-color: #fff;
    padding: 2.5rem 1.7rem 1.4rem 2.5rem;
    display: flex;
    justify-content: space-between;
}

.title {
    font-size: 2.4rem;
}

.subtitle {
    font-size: 2rem;
    margin-top: 2rem;
    font-weight: 400;
    font-style: normal;
    line-height: 4.2rem;
    color:#726F95;
    opacity: 0.7;
}

.closeBtn {
    width: 2.8rem;
    height: 2.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    flex-shrink: 0;
    margin-top: -1rem;
}

.main {
    width: 100%;
    height: 100%;
    padding: 1.3rem 3rem 3rem 3rem;
    background-color: #fff;
    margin-bottom: -.1rem;
}

.footer {
    border-radius: 0 0 20px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 2rem;
    padding: 0 2rem 2rem 2rem;
    background-color: #fff;
}

.cancelBtn {
    min-width: 18.1rem;
    min-height: 4.1rem;
    margin-right: 3rem;
}

.submitBtn {
    min-width: 18.1rem;
    min-height: 4.1rem;
}
