.wrapperCard {
  background-color: var(--white);
  padding: 3rem;
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.06);
}

.titleCard {
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 3.8rem;
  margin-bottom: 3rem;
}

.bordered {
    border-radius: 12px;
}
