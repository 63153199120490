.header,
.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header {
  padding-bottom: 3rem;
}

.mb0 {
  margin-bottom: 0;
}

.container {
  width: 100%;
}

.headerCampaigns {
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 2.8rem;
  margin-right: 1rem;
}

.btnWrapper {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
}

.addImg {
  margin-right: .4rem;
}

.addText {
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: var(--white);
}

.table,
.table2 {
  width: 100%;
  text-align: center;
  /* overflow: hidden; */
  /* height: 100%; */
}

.table2 {
  border-collapse: separate;
  border-spacing: 0 1.4rem;
}

.thead {
  border-radius: 80px;

  position: relative;
  top: 0;
  opacity: 1;
  z-index: 2;

  overflow: hidden;
}

.tbody {
  display: block;
  max-height: calc(100vh - 33.1rem);
  overflow-y: auto;
}

.dashboardBody {
  height: calc(100vh - 38rem);
  min-height: 100px;
  overflow: auto;
}

.dashboardThead {
  position: sticky;
  top: 0;
  opacity: 1;
  z-index: 2;
}

.sticky {
  position: sticky;
  top: 0;
  opacity: 1;
  z-index: 2;
  background: var(--white);
}

.dashboardThead tr {
  opacity: 1;
  background-color: #efeefe;
}

.invoicesBody {
  max-height: calc(100vh - 70.1rem);
}

.trHead {
  background-color: rgba(102, 86, 245, 0.1);
  position: relative;
}

.trTotal {
  background-color: #efeefe !important;
}

.trTotal td {
  color: #111a39;
  font-weight: 700 !important;
  background-color: #efeefe !important;
}

.trTotal {
  background-color: #efeefe !important;
}

.trTotal td::after {
  z-index: -1;
  background-color: #efeefe !important;
}

.tr {
  position: relative;
  z-index: 0;
}

.tr:nth-child(even) {
  background-color: rgba(102, 86, 245, 0.05);
  border-radius: 8px;
}

.tr:nth-child(even) {
  background-color: rgba(102, 86, 245, 0.05);
  border-radius: 8px;
}

.thA,
.tdA {
  position: relative;
  min-width: 3rem;
}

.th,
.thL {
  width: 9%;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.6rem;
  padding: 2.5rem 1rem;
}

.thNoWidth {
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.6rem;
  padding: 2.5rem 1rem;
  text-align: left;
}

.thL {
  width: 12%;
}

.thS {
  width: 8%;
}

.table_header {
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.6rem;
  padding: 2.5rem 0;
  vertical-align: middle;
}

.th1,
.th2 {
  width: 18%;
}

.th3 {
  width: 22%;
}

.th4 {
  width: 28%;
}

.td,
.tdL,
.tdP {
  width: 9%;
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: var(--gray2);
  padding: 2rem 1rem;
  text-align: center;
  vertical-align: middle;
}

.tdNoWidth {
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: var(--gray2);
  padding: 2rem 1rem;
  text-align: center;
  vertical-align: middle;
  /* display: flex;
  justify-content: center; */
}

.tdL {
  width: 12%;
}

.tdS {
  width: 8%;
}

.td1,
.td2,
.td3,
.td4 {
  width: 18%;
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: var(--gray2);
  padding: 2.5rem 0;
  text-align: center;
  vertical-align: middle;
  align-items: center;
}

.td3 {
  width: 22%;
}

.td4 {
  width: 28%;
}

.tdResync {
  color: #00ad30;
}

.tdImageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 25rem;
  width: 100%;
}

.tdImageSpan {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.th2,
.td2 {
  width: 46%;
}

.tdP {
  padding: .2rem 0;
}

.icon {
  display: inline-block;
  margin-right: 1rem;
}

.logoDataSource {
  min-width: 30px;
  height: 30px;
}

.hover {
  cursor: pointer;
}

.hover:hover {
  background-color: rgba(102, 86, 245, 0.4);
}

.choosen {
  background-color: #e8f0fe !important;
}

.green {
  color: var(--green2) !important;
}

.lightGreen {
  color: var(--green3) !important;
}

.orange {
  color: var(--orange) !important;
}

.red {
  color: var(--red) !important;
}

.checkbox {
  position: absolute;
  top: calc(50% - 1rem);
  left: 1rem;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}

.pr {
  position: relative;
  z-index: 1;
}

.edit {
  display: inline-block;
  margin-left: .5rem;
  cursor: pointer;
  transition: all 0.3s linear;
}

.progressBarContainer {
  position: relative;
  max-width: 15rem;
  margin: 0 auto;
}

.progressBarLabel {
  z-index: 1;
  padding: .5rem;
  color: var(--white);
  font-weight: bold;
  font-size: 1.2rem;
  display: initial;
}

.pause {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 0;
}

.editContainer {
  position: absolute;
  left: 2rem;
  top: 4rem;
  padding: 3rem;
  background: var(--white);
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  z-index: 1;
}

.editTitle {
  text-align: left;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2rem;
  color: var(--black);
  margin-bottom: 2rem;
}

.editBtns {
  display: flex;
  justify-content: center;
}

.error {
  color: var(--red);
  text-align: center;
  margin-bottom: 1.5rem;
  max-width: 20rem;
  font-size: 1.6rem;
}

.flexCenter {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle {
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 50%;
  background-color: #00ad30;
  margin-right: 1rem;
}

.pauseContainer {
  width: 1.4rem;
  height: 1.8rem;
  display: flex;
  justify-content: space-between;
  margin-right: 1rem;
}

.pauseElem {
  width: .5rem;
  height: 100%;
  background-color: #b3b3b3;
}

.selectContainer {
  position: absolute;
  top: 6rem;
  left: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: #fff;
  padding: 1rem 0 1rem 0;
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  overflow: hidden;
}

.selectElem {
  width: 100%;
  font-size: 1.6rem;
  padding: 2rem 3rem 2rem 3rem;
  cursor: pointer;
  white-space: nowrap;
  text-align: left;
}

.selectElem:hover {
  background-color: #f4f2ff;
}

.textLeft {
  text-align: left;
}

.campaignsSelect {
  min-width: 4rem;
  width: 5%;
}

.disabled {
  background: rgb(207, 222, 249);
  opacity: 0.6;
  border-radius: 8px;
}

@keyframes LoadingBar {
  0% {
    transform: translateX(-100%);
  }
  45% {
    transform: translateX(0);
  }
  55% {
    transform: translateX(0);
  }
  90% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.loadingBar:before {
  content: '';
  position: absolute;
  display: block;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 2%;
  animation: LoadingBar 1.5s ease-in-out infinite;
  background-image: linear-gradient(
    90deg,
    #2055fd 0%,
    #2055fd 50%,
    #2055fd 100%
  );
  -webkit-animation: LoadingBar 1.5s ease-in-out infinite;
}

.dataSourceWrapper {
  overflow: auto;
  overflow-x: hidden;
  height: 86%;
}

.dataSourceWrapperBudgetData {
  height: calc(92vh - 22rem);
  overflow: auto;
  overflow-x: hidden;
}

.dataSourceWrapper .loadingBar:before {
  z-index: 1;
}

.dashboardBodyTeam {
  height: calc(100vh - 38rem);
  overflow: auto;
  overflow-x: hidden;
}

.dashboardBodyTeam .loadingBar:before {
  z-index: 1;
}

.wrapperAlert {
  margin-bottom: -1.5rem;
  margin-left: 1.2rem;
}

.headerSort div {
  display: inline;
  position: relative;
}

.imgIcon {
  position: absolute;
  top: 0;
  left: calc(100% + .2rem);
}

.tHeadCampaign {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 1rem;
}

.campaignFilterWrapper {
  position: absolute;
  left: -3rem;
  top: 3rem;
}

.campaignFilter {
  background-color: white;
  padding: 2rem 1.6rem;
  border: 1px solid #f0effe;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 1.5rem 0;
}

.campaignFilter span {
  color: #726f95;
  font-size: 1.2rem;
  line-height: 1.2rem;
  font-weight: 500;
}

.campaignFilter div {
  display: flex;
  align-items: center;
  gap: 0 .8rem;
  cursor: pointer;
}

.selectedContainer {
  padding: 1.2rem .4rem 1.2rem .8rem;
  width: 15rem;
  height: 4.3rem;
  border: 1px solid #dddddd;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
}

.selectedFilter {
  padding: 1.2rem .4rem 1.2rem .8rem;
  height: 4rem;
  min-width: 9rem;
  border: 1px solid #dddddd;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
}

.selectedText {
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: #8a889d;
  text-transform: capitalize;
}

.noty {
  font-size: 1.3rem;
  font-weight: 500;
  color: #252331;
  margin-top: 5rem;
  display: inline-block;
}

.updateWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 1.6rem 0 1.6rem;
  cursor: pointer;
  border: none;
  background-color: #fff;
  outline: none;
}

.disabled {
  cursor: not-allowed;
}

.icon_update {
  width: 2.8rem;
}

.updateText {
  color: #2055fd;
  font-size: 1.1rem;
  margin: .6rem 0 0 0;
}

.lastUpdate {
  position: absolute;
  flex-direction: column;
  top: 2.4rem;
  right: 2rem;
  color: #2055fd;
  font-size: 1.1rem;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* Responsive */
@media (max-width: 1400px) {
  .title {
    font-size: 20px;
    white-space: nowrap;
  }

  .updateWrapper {
    margin: 0 12px;
  }
  
  .icon_update {
    width: 20px;
  }

  .selectedFilter img {
   width: 20px;
  }
}
