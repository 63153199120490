.campaignNameCell {
  display: flex;
  justify-content: center;
  text-overflow: ellipsis;
  overflow: hidden;
}

.campaignNameCellIcon {
  margin-right: 8px;
  width: 1.4rem;
  min-height: 1.4rem;
  min-width: 1.4rem;
}

.sourceImg {
  max-width: 3rem;
  max-height: 3rem;
}
