.aside {
  position: fixed;
  top: 0;
  left: 0;
  width: 30rem;
  height: 100vh;
  background: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.logo {
  padding-top: 3rem;
  padding-left: 1rem;
  margin: auto;
  margin-bottom: 7rem;
  width: 85%;
}

.nav {
  display: flex;
  flex-direction: column;
  padding: 2rem 0;
}

.navLink,
.active,
.disable,
.disableSetting,
.navLinkSetting,
.activeSetting {
  height: 6rem;
  display: flex;
  align-items: center;
  padding: 0 1.4rem;
  border-radius: 8px;
  text-decoration: none;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: var(--gray2);
  cursor: pointer;
  margin: 0 3rem;
}

.navLinkSetting {
  padding: 0;
  margin-left: 7rem;
}

.active {
  background: linear-gradient(90deg, #2055fd 14.57%, #0030c8 80.86%);
  color: var(--white);
}

.activeSetting {
  padding: 0;
  margin-left: 7rem;
  border-radius: 0;
  text-decoration-line: underline;
  text-decoration-thickness: 3px;
  text-underline-offset: 10px;
  text-decoration-color: var(--primary);
  color: var(--gray5);
}

.imgContainer {
  width: 5.6rem;
  height: 100%;
  display: grid;
  place-items: center;
}

.right {
  margin-left: auto;
}

.signOutWrapper {
  display: flex;
  align-items: center;
}

.signOutImg {
  margin-right: 2rem;
}

.signOutText {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: var(--gray2);
}

.disable {
  background: #F4F7FE;
  opacity: .7;
  cursor: not-allowed;
  margin: 0;
  border-radius: 0;
  padding-left: 4.4rem;
}

.disableSetting {
  background: #F4F7FE;
  opacity: .7;
  cursor: not-allowed;
  margin: 0;
  border-radius: 0;
  padding-left: 7rem;
}

.warningTabDataSource {
  margin-left: 1rem;
}

.imgContainer img {
  width: 2.7rem;
}