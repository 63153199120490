.wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 8px;
    background: var(--primary);
}

.container {
    display: inline-block;
    padding: 3.1rem 2.4rem;
    border-right: 1px solid var(--white);
}

.text {
    font-size: 2rem;
    line-height: 2.3rem;
    color: var(--white);
}