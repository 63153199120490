.wrapperTable {
  margin: 0 auto;
  width: 100%;
  max-width: 100%;
  position: relative;
}

.mainTable {
  font-size: 1.4rem;
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;

  /*--*/
  /* table-layout: fixed; */
}

.tableLayoutFixed {
  table-layout: fixed;
}

.headerTable {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #efeefe;
}

.headerTable tr th {
  position: relative;
  background: #efeefe;
  font-weight: 700;
  padding: 2.5rem 1rem;
  font-size: 1.4rem;
  line-height: 1.6rem;
  z-index: 1;
  text-align: center;
}

.sortHeader {
  cursor: pointer;
  color: #2055FD;
}

.loadingBar::before {
  content: '';
  position: absolute;
  display: block;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 0.2rem;
  animation: LoadingBar 1.5s ease-in-out infinite;
  background-image: linear-gradient(
    90deg,
    #2055fd 0%,
    #2055fd 50%,
    #2055fd 100%
  );
  -webkit-animation: LoadingBar 1.5s ease-in-out infinite;
  z-index: 30;
}

.bodyTable tr td {
  position: relative;
  border-bottom: 0.1rem solid #e5e5e5;
  font-weight: 500;
  border: none;
  background-color: #fff;
  text-align: center;
}

.bodyTable td > div {
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

.bodyTable tr:nth-child(even) td::after{
  content: "";
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  background-color: rgba(102, 86, 245, 0.05);
  pointer-events: none;
}

tr td {
  padding: 2rem 1rem;
  text-align: left;
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: var(--gray2);
  vertical-align: middle;
  font-weight: 500;
}

.overfolowXHidden {
  overflow-x: clip;
}

.resizer {
  position: absolute;
  top: 0;
  right: 0;
  width: 5px;
  padding: 0 .5rem;
  cursor: col-resize;
  user-select: none;
  height: 60px;
}

.resizer:hover,
.resizing {
  border-right: 2px solid #ccc;
  opacity: .8;
}

.stickyPos {
  position: sticky !important;
}

@keyframes LoadingBar {
  0% {
    transform: translateX(-100%);
  }
  45% {
    transform: translateX(0);
  }
  55% {
    transform: translateX(0);
  }
  90% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
}
