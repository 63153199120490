.tagsInputContainer {
  width: 100%;
  min-height: 7rem;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.9rem;
  border: 1px solid var(--gray);
  border-radius: 6px;
  outline: none;
  color: var(--gray2) !important;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 2rem .8rem 2rem 0.8rem;
  column-gap: 1rem;
  row-gap: 1rem;
}

.tagsInputContainer input {
  flex-grow: 1;
  border: none;
  outline: none;
  text-indent: 2.5rem;
}

.tagsInputContainer input::placeholder {
  font-size: 1.6rem;
  line-height: 2.9rem;
  color: var(--gray);
  text-indent: 2.5rem;
}
