.mainWrapper {
    padding-top: 14rem;
}

.wrapper {
    padding: 0 3rem 3rem;
}

.content {
    padding: 3rem;
    background: var(--white);
    border-radius: 8px;
}

.title {
    color: var(--black);
    font-size: 2rem;
}

.label {
    font-size: 1.6rem;
}

.inputContainer {
    min-width: 50%;
    width: 40rem;
}

.tableHead {
    padding: 2rem;
    display: grid;
    grid-template-columns: 4fr 1fr 1fr 1fr;
}

.row {
    display: grid;
    grid-template-columns: 4fr 1fr 1fr 1fr;
    background-color: rgba(102, 86, 245, 0.05);
    margin: 1rem 0;
    padding: 2rem;
    border-radius: 8px;
}

.row div {
    font-size: 1.6rem;
}

.circleWrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
}

.circleWrapper div {
    font-size: 1.6rem;
}

.circleContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.3rem;
    height: 2.3rem;
    border-radius: 50%;
    border: 2px solid #2055FD;
    margin-bottom: 1rem;
}

.circle {
    border-radius: 50%;
    width: 1.4rem;
    height: 1.4rem;
    background-color: #2055FD;
}

.footer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkMark {
    width: 2rem;
}

.error {
    color: var(--red);
    margin: .5rem 0;
    font-size: 1.6rem;
}