.metric {
  display: flex;
  align-items: center;
  column-gap: 1.2rem;
  margin-bottom: .8rem;
  padding: 1.2rem 0;
  margin-left: 1rem;
}

.metric input {
  position: static;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 2px;
}

.metric label {
  color: #726f95;
  font-size: 1.6rem;
  line-height: normal;
  font-weight: 200;
  cursor: pointer;
}

