.wrapper,
.miniWrapper,
.settingsWrapper {
  /*
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 100vh;
  overflow-y: auto;
  */
  padding: 3rem;
  width: 100%;
  max-width: 69rem;
  background: var(--white);
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  z-index: 11;
  margin: 4rem auto 5rem;
}

.miniWrapper {
  left: calc(50% + 8vw);
}

.closeBtn {
  cursor: pointer;
}

.wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.settingsWrapper {
  position: static;
  transform: unset;
  margin: 0 auto;
  margin-bottom: 3.6rem;
}

.googleWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.googleLogo {
  margin-right: 1.6rem;
}

.errorText {
  color: var(--red);
  white-space: pre-line;
  font-size: 1.6rem;
}

.googleText {
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 1.9rem;
}

.orWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.4rem;
}

.orHr {
  content: '';
  height: 0.1rem;
  border: none;
  width: 100%;
  background: var(--gray);
}

.orText {
  font-size: 1.6rem;
  line-height: 2rem;
  margin: 0 2rem;
}

.title {
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 3.8rem;
  margin-bottom: 3rem;
}

.titleForPayment {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 3.3rem;
  color: #111a39;
  margin: 0 0 1.6rem 0;
}

.wrapForFields {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.formInfo {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: #726f95;
  margin-top: -1.5rem;
}

.wrapForField {
  width: 47%;
}

.width {
  width: 49%;
}

.form > input,
.df {
  margin-bottom: 2.4rem;
}

.df {
  display: flex;
  justify-content: space-between;
}

.formTitleWrapper {
  width: 100%;
  display: flex;
  align-items: center;
}

.formTitle {
  font-size: 1.8rem;
  line-height: 2.1rem;
  margin-bottom: 1.6rem;
  color: var(--black2);
}

.formIcon {
  width: 1.4rem;
  height: 1.4rem;
  margin-left: 0.4rem;
  margin-bottom: 1.6rem;
}

.linksWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1.6rem;
  margin-bottom: 2.4rem;
}

.checkboxText {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.6rem;
  color: var(--gray2);
}

.checkbox {
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 0.8rem;
}

.forgotLink,
.regLink,
.footerText {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: var(--primary);
  text-decoration: none;
}

.forgotLink:hover,
.regLink:hover {
  text-decoration: underline;
}

.footerText {
  color: var(--gray2);
  text-align: center;
}

.btnsWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 4rem;
}

.error {
  color: var(--red);
}

.deleteText {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2rem;
  margin-bottom: 1.6rem;
  color: var(--gray2);
}

.warnText {
  font-size: 1.3rem;
  line-height: 1.5rem;
  color: var(--gray2);
  margin-bottom: 1.2rem;
}

.imgsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  padding-top: 4rem;
  margin: 0 auto;
}

.error {
  color: var(--red);
  margin-bottom: 1.5rem;
  white-space: pre-line;
  font-size: 1.6rem;
}

.hoverImgWrapper {
  position: relative;
}

.hoverContainer {
  position: absolute;
  left: 3rem;
  top: 0;
  z-index: 5;

  width: 29.5rem;
  background: var(--white);
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  padding: 1.2rem;

  transition: all 0.3s linear;
}

.hoverTitle {
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: var(--gray2);
}

.createWrapper {
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;

  padding: 3rem;
  width: 100%;
  max-width: 69rem;
  background: var(--white);
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  z-index: 11;
  margin: 0;

  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media (max-width: 767px) {
  .createWrapper {
    max-width: 90%;
  }

  .wrapper,
  .miniWrapper,
  .settingsWrapper {
    width: 90%;
    position: initial;
    transform: initial;
    margin: 50px auto 30px;
  }

  .title {
    font-size: 24px;
    margin-bottom: 24px;
    line-height: 28px;
  }

  .formTitle {
    font-size: 16px;
  }

  .form > input {
    height: 55px;
    font-size: 14px;
    text-indent: 20px;
  }

  .form .button {
    height: 48px;
  }
}

.loader {
  position: absolute;
  z-index: 2;
  width: 7rem;
  height: 7rem;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.containerForLoader {
  z-index: 1;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 86%;
  height: 65%;
}

.placeholder::placeholder {
  font-size: 1.6rem;
  color: #726f95;
}
