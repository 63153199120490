.container {
  position: relative;
  margin-bottom: 2.4rem;
}

/* Responsive */ 
@media (max-width: 1400px) {
  .container {
    margin-bottom: 20px;
  }
}
