.progressBar {
  position: relative;
  height: 1.3rem;
}

.progressBarLayer {
  position: absolute;
  top: 0;
  bottom: 0;
  background: #e9eafe;
  border-radius: 25px;
  overflow: hidden;
  max-width: 100%;
  cursor: pointer;
  width: 100%;
}

.firstLayer {
  background: #27ae60;
  z-index: 3;
  border-radius: 25px;
}

.secondLayer {
  background: repeating-linear-gradient(
    -65deg,
    #e9eafe,
    #e9eafe 1px,
    rgba(39, 174, 96, 0.24) 5px,
    rgba(39, 174, 96, 0.24) 10px
  );

  z-index: 2;
}

.thirdLayer {
  background: #e9eafe;
  z-index: 1;
}

.progressTooltip {
  position: absolute !important;
}