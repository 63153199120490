.mainWrapper {
    position: relative;
    top: 0;
    left: 30em;
    width: calc(100vw - 30rem);
    min-width: 78rem;
}

.errorFallback {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    color: #721c24;
  }

.errorText {
    font-size: 24px;
    margin-bottom: 20px;
}

.errorFallback button {
    background-color: #f53f3f;
    color: white;
    border: none;
    padding: 12px 24px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 10px;
  }

.errorFallback button:hover {
    background-color: #e53e3e;
  }