@media (max-width: 1080px) {
  .createAccount div {
    max-width: 100%;
    height: 100%;
  }

  .createAccount img {
    display: none !important;
  }

  .createAccount h3 {
    color: #726f95;
    font-size: 16px;
    margin-bottom: 20px;
  }
}

.createAccount h3 {
  color: #726f95;
  font-size: 16px;
  margin-bottom: 20px;
}
