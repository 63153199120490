.wrapperSignUpPage {
  position: relative;
  height: 100vh;
}

.wrapperBanner {
  position: fixed;
  top: 0;
  left: 0;
  width: 36rem;
  height: 100vh;
  background-color: #1649ec;
  overflow: hidden;
}

.wrapperBanner > div {
  display: flex;
  flex-direction: column;
  margin-left: 4rem;
  height: 100%;
  justify-content: space-between;
}

.airtomicLogo {
  width: 16.6rem;
  height: 4.9rem;
  margin-top: 3.6rem;
}

.background {
  position: absolute;
  top: 0;
}

.wrapperContent {
  margin-top: 26rem;
  display: flex;
  flex-direction: column;
}

.stars {
  margin-bottom: 1.6rem;
  width: 10.8rem;
}

.logoCapterra img {
  max-width: 30px;
  height: 30px;
}

.capterra {
  width: 100px;
}

.logoCapterra2 img {
  max-width: 30px;
  height: 30px;
}

.capterraContainer {
  display: flex;
  flex-direction: row;
  column-gap: 15px;
}

.capterraContainer > div {
  display: flex;
  flex-direction: row;
}

.lineSplit {
  width: 1px;
  height: 30px;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0.7) 46.61%,
    rgba(255, 255, 255, 0.1) 100%
  );
}

.lineDash {
  margin: 25px 0;
  padding-right: 4rem;
}

.reviewText {
  color: var(--white);
  font-size: 1.6rem;
  font-weight: 200;
  line-height: 2.4rem;
  letter-spacing: -0.4px;
  padding-right: 4rem;
  margin-bottom: 2.5rem;
}

.promotionText h4 {
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 500;
  color: var(--white);
}

.promotionText span {
  color: var(--white);
  font-size: 1.4rem;
  line-height: 2.4rem;
  font-weight: 200;
}

.dashboardBackground {
  margin-top: 4rem;
  height: 362px;
  border-radius: 10px 0px 0px 0px;
  background: url('./../../assets/dashboardBackground.webp'),
    lightgray -110.371px -207.77px / 159.091% 157.741% no-repeat;
  background-size: cover;
  background-repeat: no-repeat;
}

.auth_form_wrapper {
  padding: 0 24px;
}

.wrapperSignUpPage main {
  position: relative;
  z-index: 1;
}

@media (min-width: 769px) {
  .wrapperSignUpPage main {
    top: 0;
    left: 36rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100vw - 36rem);
  }
}

@media (max-width: 768px) {
  .wrapperBanner {
    width: 100%;
    overflow-y: auto;
  }

  .wrapperContent {
    margin-top: 0;
  }

  .auth_form_wrapper {
    padding: 0;
  }

  .auth_form_wrapper div {
    margin-left: 12px;
  }
}

@media (max-width: 480px) {
  .mainSignup {
    margin-left: -4rem;
    width: 100vw;
  }
  .auth_form_wrapper > div {
    margin-top: 1rem;
    margin-left: 2rem;
  }
}
