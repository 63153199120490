.overlay {
  position: fixed;
  top: 10rem;
  left: 30em;
  width: calc(100vw - 30rem);
  height: 100%;
  background: rgba(244, 247, 254, 0.7);
  z-index: 4;
}

.wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.closeBtn {
  cursor: pointer;
  margin: 0 -1.6rem 1.4rem 0;
}

.close {
  color: #726f95;
  border: 1px solid #726f95;
  width: 17.1rem;
  height: 4.5rem;
  border-radius: 5px;
  background: white;
  cursor: pointer;
  font-size: 1.4rem;
}

.wrapper,
.tableWrapper {
  position: fixed;
  top: calc(50% + 5rem);
  left: calc(50% + 8vw);
  transform: translate(-50%, -50%);
  border-radius: 12px;
  padding: 3rem 4rem;
  width: 100%;
  max-width: 64rem;
  max-height: 70vh;
  background: var(--white);
  z-index: 4;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.wrapperForPayment {
  position: fixed;
  top: 50%;
  left: calc(50% + 15rem);
  transform: translate(-50%, -50%);
  border-radius: 12px;
  padding: 3rem 4rem;
  background: var(--white);
  z-index: 8;
  width: 70.8rem;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.wrapper::-webkit-scrollbar {
  display: none;
}

.tableWrapper {
  border-radius: 0;
  max-width: 60vw;
}

.title,
.title2 {
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.3rem;
  margin-bottom: 3.6rem;
}

.title2 {
  margin-bottom: 1.4rem;
}

.sourcesWrapper {
  height: 12rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 4.5rem;
  padding: 0 1rem;
  column-gap: 1.6rem;
}

.sourceWrapper {
  height: 100%;
  width: 100%;
  padding: 1.5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 0.5px solid var(--gray);
  border-radius: 6px;
  cursor: pointer;
}

.sourceWrapper img {
  height: 65px;
}

.btnsWrapper,
.btnWrapper {
  width: 100%;
  max-width: 35rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.btnWrapper {
  max-width: 16.2rem;
  justify-content: center;
}

.text {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: var(--gray2);
  margin-bottom: 3.4rem;
}

.btns {
  width: 70%;
  margin: 0 auto;
  display: flex;
}

.sticky {
  position: sticky;
  top: 0;
  opacity: 1;
  z-index: 2;
  background: var(--white);
  margin-bottom: 0;
  padding-bottom: 3rem;
}

.errorText {
  color: rgb(246 93 101);
  margin: 0 0 1rem 0;
}

.paymentFormLoading {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 50%;
  background: #f4f7feb3;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}
