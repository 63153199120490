.campaignName {
  position: absolute;
  padding: 1.5rem 1rem;
  border-radius: 8px;
  width: 22.3rem;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.05);
  z-index: 999;
}

.campaignName h4 {
  font-size: 1.4rem;
  font-weight: 500;
  color: #726f95;
  margin-bottom: 1.2rem;
}

.containerSelect {
  display: flex;
  flex-direction: column;
  row-gap: 1.8rem;
}

.selectPosLeft > div {
  margin-bottom: 0;
}

.selectPosLeft > div > div > div {
  height: 3.7rem;
}

.containerValueString {
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
}

.containerValueString input {
  height: 3.7rem;
}

.labelValueString {
  display: inline-block;
  color: #726f95;
  font-size: 1.2rem;
  font-weight: 400;
}

.helpText {
  font-style: italic;
}

.inputValue {
  font-size: 1.4rem;
  font-weight: 400;
  padding: 1rem;
  resize: none;
}

.inputValue::placeholder {
  font-size: 1.4rem;
  font-weight: 400;
}

.wrapperBtn {
  text-align: right;
}

.applyBtn {
  outline: none;
  border: none;
  background-color: transparent;
  padding: .6rem 1rem;
  color: var(--primary);
  font-size: 1.4rem;
  cursor: pointer;
}

.error {
  color: var(--red);
}
