.main {
  padding: 5rem;
  padding-top: 14rem;
}

.nav,
.step {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.step {
  text-decoration: none;
}

.nav {
  position: relative;
  background: var(--white);
  padding: 1.7rem 16rem;
  border-radius: 12px;
  margin-bottom: 3rem;
  overflow: hidden;
}

.round {
  margin-right: 1.6rem;
  width: 6.4rem;
  height: 6.4rem;
  display: grid;
  place-items: center;
  border: 1px dashed var(--gray2);
  border-radius: 50%;
}

.stepNumber,
.stepText {
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: var(--gray2);
}

.active {
  color: var(--primary);
}

.activeB {
  border-color: var(--primary);
}

.disabled {
  pointer-events: none;
}

.title {
  font-weight: 700;
  font-size: 3rem;
  line-height: 5;
  text-align: center;
}

/* Responsive */
@media (max-width: 1400px) {
  .main {
    padding: 120px 24px 16px 24px;
  }

  .nav {
    padding: 16px 40px;
  }

  .round {
    width: 52px;
    height: 52px;
  }

  .stepNumber,
  .stepText {
    font-size: 14px;
  }
}
