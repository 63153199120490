.button {
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 1.9rem;
  height: 7rem;
  width: 100%;
  border: 1px solid transparent;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease-out 0.1s;
  white-space: nowrap;
}

.google {
  background: var(--stroke);
  margin-bottom: 3rem;
}

.google:hover {
  border-color: var(--black);
}

.submit {
  background: var(--primary);
  color: var(--white);
}

.submit:not([disabled]):hover,
.payment:not([disabled]):hover {
  border-color: var(--primary);
  background: var(--white);
  color: var(--primary);
}

.user {
  height: 5rem;
  width: 13.2rem;
  border-radius: 100px;
  background: var(--primary);
}

.nav {
  padding: 0;
  height: 5rem;
  background: var(--white);
  margin-right: 3rem;
}

.signOut {
  background: var(--white);
  padding: 0 5rem;
  margin-bottom: 6rem;
}

.close {
  background: var(--gray);
  color: var(--white);
  margin-right: 1.6rem;
}

.cancel {
  background: #b1b7ca;
  border-radius: 6px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 1.9rem;
  text-align: center;
  color: #ffffff;
}

.deleteAcc, .cancel_subscription {
  background: rgba(232, 76, 76, 0.1);
  color: #e84c4c;
  border: 1px solid rgba(232, 76, 76, 0.9);
}

.cancel_subscription {
  width: auto;
  height: 5.6rem;
  font-size: 1.4rem;
  line-height: 1.6rem;
  padding: 0 2.4rem;
}

.addSource {
  width: 15rem;
  height: 4.5rem;
  background: var(--primary);
}

.cancelBtn {
  font-weight: 500;
  width: 16rem;
  height: 5.6rem;
  background: var(--gray);
  color: var(--white);
}

.addClient,
.tableMenu,
.tableMenuBN {
  font-weight: 500;
  width: 16rem;
  height: 5.6rem;
  background: var(--primary);
  color: var(--white);
}

.tableMenu,
.tableMenuBN {
  width: auto;
  padding: 0 5.6rem;
  height: 3rem;
  border-right: 1px solid var(--white);
  font-size: 2rem;
  line-height: 2.3rem;
}

.tableMenuBN {
  border: none;
}

.close,
.delete {
  font-size: 1.4rem;
  line-height: 1.6rem;
  height: 4.5rem;
  max-width: 16.2rem;
  margin: 0;
}

.disconnect,
.reconnect,
.primary {
  width: auto;
  height: auto;
  font-size: 1.2rem;
  background: transparent;
}

.disconnect {
  color: var(--red);
}

.reconnect {
  color: var(--green);
}

.delete {
  border: 1px solid var(--red2);
  background: var(--white);
  color: var(--red2);
}

.primary {
  color: var(--primary);
  cursor: pointer;
}

.datesPicker {
  height: 4.2rem;
  width: 26rem;
  padding-left: 3rem;
  padding-right: 0;
  background: var(--white);
  background-image: url(../../../assets/date.svg);
  background-repeat: no-repeat;
  background-position: 1rem center;
  border: 1px solid #dddddd;
  border-radius: 4px;
  font-size: 1.4rem;
  font-weight: 500;
  color: var(--gray2);
}

.datesPickerCancel {
  height: auto;
  font-size: 1.6rem;
  line-height: 1, 9rem;
  color: var(--gray2);
  background: transparent;
}

.datesPickerApply {
  height: auto;
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: var(--white);
  padding: 1rem 3rem;
  background: var(--primary);
  border-radius: 100px;
}

.payment {
  width: auto;
  height: 5.6rem;
  color: var(--white);
  background: var(--primary);
  border: 1px solid transparent;
  font-size: 1.4rem;
  line-height: 1.6rem;
  padding: 0 2.4rem;
}

.download {
  background-image: url(../../../assets/download.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-color: var(--white);
  width: 5rem;
  height: 2rem;
}

.edit {
  background: transparent;
  height: auto;
  width: auto;
  font-weight: 500;
  color: var(--primary2);
  padding: 0 2rem;
}

.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

@media (max-width: 767px) {
  .button {
    height: 48px;
  }
}

/* Responsive */
@media (max-width: 1400px) {
  .nav {
    margin-right: 20px;
  }

  .addClient {
    width: 140px;
    font-size: 14px;
    height: 48px;
  }

  .button {
    height: 40px;
  }
}

@media (max-width: 1600px) {
  .payment {
    padding: 0 1.2rem;
  }
}
