.input {
  width: 100%;
  height: 7rem;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.9rem;
  border: 1px solid var(--gray);
  border-radius: 6px;
  outline: none;
  text-indent: 2.5rem;
  color: var(--gray2) !important;
}

.input::placeholder {
  font-size: 1.6rem;
  line-height: 2.9rem;
  color: var(--gray);
  text-indent: 2.5rem;
}

.placeholder::placeholder {
  color: #726f95 !important;
  font-weight: 400 !important;
}

.search {
  height: 4.5rem;
  max-width: 30rem;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.9rem;
  background: #f5f8ff;
  border: 1px solid #ededed;
  border-radius: 50px;
  background-image: url(../../../assets/search.svg);
  background-repeat: no-repeat;
  background-position: 92% center;
  appearance: none;
}

.search::placeholder {
  color: rgb(74, 72, 94, 0.5);
}

.mb {
  margin-bottom: 2.4rem;
}

.mb15 {
  margin-bottom: 1.5rem;
}

.edit {
  width: 21.6rem;
  height: 5.5rem;
  margin-bottom: 2rem;
}

/* Responsive */ 
@media (max-width: 1400px) {
  .search {
    width: 180px !important;
    height: 38px !important;
  }

  .input {
    height: 60px;
  }

  .mb {
    margin-bottom: 20px;
  }
}