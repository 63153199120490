.wrapper {
  background: var(--white);
  padding: 3rem 4rem;
  border-radius: 12px;
  margin-bottom: 4rem;
}

.title {
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 2.8rem;
  margin-bottom: 1rem;
}

.text {
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 3.4rem;
  color: var(--gray3);
  margin-bottom: 5rem;
}

.container {
  width: 45%;
  margin: 0 auto;
}

.btn {
  width: 100%;
  display: flex;
  justify-content: end;
}

.select {
  font-size: 1.8rem;
  line-height: 2.1rem;
  color: var(--black2);
  margin-bottom: 1.6rem;
}

.dateContainer {
  width: 100%;
  height: 7rem;
  margin-bottom: 2.4rem;
}

.inputDate {
  width: 100%;
  height: 7rem;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.9rem;
  border: 1px solid var(--gray);
  border-radius: 6px;
  outline: none;
  text-indent: 2.5rem;
  color: var(--gray2);
}

.inputDate::placeholder {
  font-size: 1.6rem;
  line-height: 2.9rem;
  color: var(--gray);
  text-indent: 2.5rem;
}

.popperDatepicker div {
  font-size: 1.4rem;
}

.popperDatepicker>div:nth-child(1)>div:nth-child(1)>div:last-child>div>div:last-child div {
  width: 3.6rem !important;
  height: 3.6rem !important;
  display: inline-grid !important;
  justify-items: center;
  align-items: center;
}

.dayDatepicker {
  width: 3.6rem !important;
  height: 3.6rem !important;
  display: inline-grid !important;
  justify-items: center;
  align-items: center;
}

.react-datepicker__day-name {
  color: red;
}

.error {
  color: var(--red);
  font-size: 1.6rem;
}

/* Responsive */
@media (max-width: 1400px) {
  .title {
    font-size: 2rem;
  }

  .wrapper {
    padding: 2rem 2.8rem 1.2rem 2.8rem;
  }

  .text {
    font-size: 1.6rem;
    line-height: 2.8rem;
    margin-bottom: 2rem;
  }

  .select {
    font-size: 1.6rem;
  }

  .inputDate {
    height: 6rem;
    margin-bottom: 1.6rem;
  }

  .dateContainer {
    height: 6rem;
  }
}