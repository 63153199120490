.skeletonWrapper {
  overflow: hidden;
}

.skeleton {
  width: 100%;
  min-width: 8rem;
  height: 2.4rem;
  padding: 1.2rem 1rem;
  background: linear-gradient(90deg, #ebebeb, #f5f5f5, #ebebeb);
  background-size: 200% 100%;
  animation: loading 1.5s ease-in-out infinite;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
