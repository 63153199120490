.searchInputWrapper {
  width: 100%;
  border: 1px solid #1473e6;
  border-radius: 6px;
  padding: 1.8rem 2rem;
  display: flex;
}

.searchInputWrapper img {
  cursor: pointer;
}

.searchInputWrapper input {
  border: none;
  outline: none;
  background-color: transparent;
  width: 100%;
  color: #726f95;
  font-size: 2rem;
  font-weight: 400;
}
