.wrapper {
  background: var(--white);
  padding: 3rem 4rem;
  border-radius: 12px;
}

.header {
  display: flex;
  justify-content: space-between;
}

.title {
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 2.4rem;
}

.text {
  width: 64%;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 3.4rem;
  color: var(--gray3);
  margin-bottom: 3rem;
}

.select {
  font-size: 1.8rem;
  line-height: 2.1rem;
  color: var(--black2);
  margin-bottom: 1.6rem;
}

/* Responsive */
@media (max-width: 1400px) {
  .wrapper {
    padding: 20px 28px 1px 28px;
  }

  .title {
    font-size: 20px;
  }

  .text {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 20px;
  }

  .select {
    font-size: 16px;
  }
}
