.main {
    height: 100vh;
    padding: 3rem;
    padding-top: 14rem;
}

.wrapper {
    width: 100%;
    height: 100%;
    background: var(--white);
    border-radius: 8px;
    padding: 3rem;
}