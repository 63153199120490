.notif {
  width: auto;
  height: 4.5rem;
  background: #FFD7B4;
  position: fixed;
  z-index: 999;
  top: 2.3rem;
  left: 48.6rem;
  display: flex;
  align-items: center;
  padding: .5rem 1rem;
  border-radius: 6px;
}

.notifImage {
  color: #F89238;
  border: 2px solid #F89238;
  border-radius: 50%;
  padding: .1rem .6rem;
  font-size: 1.3rem;
  margin: 0 0px .3rem .5rem;
}

.notifText {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: #111a39;
  margin: 0 0 0 .7rem;
}
