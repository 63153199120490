.paginationWrapper {
    display: flex;
    list-style: none;
}

.paginationItem {
    padding: 0 1rem;
    height: 3.2rem;
    display: flex;
    justify-content: center;
    margin: auto .4rem;
    color: #9492A5;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: .1rem;
    border-radius: 6px;
    font-size: 1.4rem;
    min-width: 3.2rem;
    cursor: pointer;
    transition: all .1s ease-in;
}

.paginationItem:hover {
    background-color: #eceef6;
    color: #9492A5;
}

.paginationItem.dots:hover {
    background-color: transparent;
    cursor: default;
}

.paginationItem.selected {
    background-color: #3c56fd;
    color: white;
}

.paginationItem.disabled {
    background-color: transparent;
    cursor: not-allowed;
}
