.allCampaignCondition {
  position: absolute;
  padding: 1.5rem 1rem;
  border-radius: 8px;
  width: 22.3rem;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.05);
}

.allCampaignCondition h4 {
  font-size: 1.4rem;
  font-weight: 500;
  color: #726f95;
  margin-bottom: 1.2rem;
}

.wrapperSelect {
  position: relative;
}

.wrapperSelect > div {
  margin-bottom: 0;
}

.wrapperSelect > div > div > div {
  height: 3.7rem;
}
