.wrapperBodyRuleDetails > div:not(:last-child) {
  margin-bottom: 2.4rem;
}

.labelInput {
  font-size: 1.8rem;
  line-height: 2.1rem;
  margin-bottom: 1.6rem;
  color: var(--black2);
}
