.header {
  width: 100%;
  display: flex;
  justify-content: start;
  border-bottom: 1px solid var(--gray4);
  margin-bottom: 3rem;
}

.noMargin {
  margin-bottom: 0;
}

.jce {
  justify-content: end;
}

.tab {
  padding: 1.5rem 0;
  margin-right: 3rem;
  border-bottom: 2px solid transparent;
  cursor: pointer;
}

.tabText {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: var(--gray2);
}

.tabTextM {
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: var(--gray3);
}

.tabTextL {
  font-size: 2rem;
  line-height: 2.3rem;
  color: var(--gray2);
}

.tabTextXL {
  font-size: 2.4rem;
  line-height: 2.8rem;
  color: var(--gray2);
}

.graphTabs {
  font-size: 1.4rem;
  color: var(--gray2);
}

.graphTab {
  border: 1px solid #dddddd;
  border-radius: 4px;
  padding: 1.2rem 2.2rem;
  margin-right: .8rem;
  background: var(--white);
  line-height: 1.4rem;
}

.active {
  color: var(--black);
}

.activeB {
  border-color: var(--primary);
}

.blue {
  color: var(--primary);
}

.bn {
  border: none;
}

.arrow {
  fill: var(--gray2);
  margin-left: auto;
  margin-right: .8rem;
  margin-bottom: 1rem;
}

.selectedContainer {
  padding: 1.2rem .8rem;
  width: 20.8rem;
  height: 4.8rem;
  border: 1px solid #dddddd;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.selectedText {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: #8a889d;
}

.btnWrapper {
  display: flex;
  justify-content: center;
}

.addText {
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: var(--white);
}

/* Responsive */
@media (max-width: 1400px) {
  .tabTextM {
    font-size: 14px;
  }

  .header {
    margin-bottom: 20px;
  }
}