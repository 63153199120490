.StripeElement {
  width: 100%;
  height: 60px;
  border: 1px solid var(--gray);
  border-radius: 6px;
  outline: none;
  text-indent: 2.5rem;
  color: var(--gray2) !important;
  margin: 0 0 2rem 0;
  padding: 2.2rem 2.5rem;
}

.__PrivateStripeElement {
}

.StripeElement--focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.3);
}

.StripeElement--invalid {
  border-color: #dc3545;
}

/* Additional styles for individual elements */

.StripeElement--cardNumber {
  /* Styles for CardNumberElement */
}

.StripeElement--cardExpiry {
  /* Styles for CardExpiryElement */
}

.StripeElement--cardCvc {
  /* Styles for CardCvcElement */
}
