.toolTipWrapper {
  position: relative;
  height: 100%;
  cursor: pointer;
}

.toolTipWrapper .toolTipText {
  opacity: 0;
}

.toolTipWrapper:hover .toolTipText {
  opacity: 1;
}

.toolTipText {
  position: absolute;
  text-align: center;
  border-radius: 6px;
  color: #010101;
  background-color: #f0effe;
  transition: opacity .3s;
  padding: 6px;
  bottom: 160%;
  z-index: 999;
  font-size: 12px;
  font-weight: 600;
  left: 50%;
  transform: translateX(-50%);
}

.toolTipText::after {
  content: ' ';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #f0effe transparent transparent transparent;
}
