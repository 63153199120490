.main {
  padding: 5rem;
  padding-top: 14rem;
  padding-bottom: 3rem;
}

.calendarContainer {
  width: 100%;
  height: 100%;
  margin-bottom: 2.6rem;
  background: var(--white);
  padding: 3rem;
  border-radius: 8px;
}

.container {
  width: 100%;
  height: 100%;
  margin-bottom: 2.6rem;
}

.tabsWrapper {
  background: var(--white);
  border-radius: 8px;
  margin-bottom: 2.6rem;
}

.tableWrapper {
  background: var(--white);
  border-radius: 8px;
  padding: 3rem;
}

.tableWrapper > div {
  margin-bottom: 0;
}

.content {
  /* max-height: 82vh; */
  overflow: hidden;
}

.mb {
  margin-bottom: 4.5rem;
}

.header {
  display: flex;
}

.divider {
  height: 1.5rem;
}

.modalText {
  margin-bottom: 2rem;
  opacity: 0.7;
  max-width: 56.5rem;
  font-size: 2rem;
  font-weight: 400;
  font-style: normal;
  line-height: 3.2rem;
  color: #726f95;
}

.padding {
  padding: 1rem 3rem 0 3rem;
}

.tabContainer {
  position: relative;
}

.selectWrapper {
  position: absolute;
  right: 60px;
  top: 0;
  width: 20rem;
}

.selectWrapper > div > div > div {
  height: 3.2rem;
}

.viewSelect div {
  cursor: pointer;
}

.viewSelect input {
  width: 0 !important;
  min-width: 0 !important;
}

.viewOption {
  padding: 0 12px 4px 12px !important;
}

.viewOption:active {
  background-color: transparent !important;
}

.viewOption .labelContainer {
  border-radius: 4px;
  margin-top: 0.4rem;
  padding: 0.8rem;
}

.viewOption .labelContainer:hover {
  background-color: #e1e8fb;
  color: #2055fd;
}

.viewOptionSelected .labelContainer {
  background-color: #e1e8fb !important;
  color: #2055fd !important;
}

.viewOptionModifyColumn {
  border-top: 1px solid #e1e1e1;
  padding-left: 2rem !important;
}

.valueSelect {
  font-size: 1.3rem;
  color: var(--gray2);
}

.customViewSelection {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.closeIcon {
  min-height: 16px;
  min-width: 16px;
}

.customViewSelection > div {
  padding: 0.8rem;
}

.customViewLabelSelection {
  overflow: hidden;
  width: 8rem;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.customViewAction {
  display: flex;
  align-items: center;
  column-gap: .4rem;
}

.customViewSelection img {
  opacity: 0;;
  transition: all .2s ease-in;
}

.customViewSelection:hover img {
  opacity: 1;
}

@keyframes LoadingBar {
  0% {
    transform: translateX(-100%);
  }
  45% {
    transform: translateX(0);
  }
  55% {
    transform: translateX(0);
  }
  90% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.loadingBar {
  position: relative;
  width: 100%;
  height: 0.3rem;
  overflow: hidden;
}

.loadingBar:before {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  animation: LoadingBar 1.5s ease-in-out infinite;
  background-image: linear-gradient(
    90deg,
    #2055fd 0%,
    #2055fd 50%,
    #2055fd 100%
  );
  -webkit-animation: LoadingBar 1.5s ease-in-out infinite;
}
