@media(max-width:767px) {

    .auth-form-wrapper .logo {
        padding-left: 0;
        margin: 0 auto;
        max-width: 60%;
    }

    .create-account-wrapper .sub-title {
        line-height: 1.3em;
        font-size: 14px;
        font-style: italic;
    }

    .auth-footer-text p a,
    .auth-footer-text p {
        font-size: 14px;
    }

    .auth-footer-text .term-text {
        margin: 15px 0 0;
        line-height: 2;
    }

    .create-account-wrapper {
        background-image: url("../assets/create-account-bg-mobile.png");
    }

    .form-control {
        height: 55px;
        font-size: 14px;
        padding: 0 15px;
    }

    .create-success-wrapper button {
        margin-top: 5px;
    }

    .select-wrapper .arrow {
        top: 28px;
        right: 15px;
        z-index: 1;
    }
}

@media(max-width:1024px) {
    .logo {
        padding-left: 0;
        margin: 0 auto;
        max-width: 60%;
    }
}