.wrapperTag {
  width: fit-content;
  color: var(--white);
  line-height: 2rem;
  padding: 0.3rem 1rem 0.6rem 1rem;
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 700;
}

.tagCloseIcon {
  cursor: pointer;
  width: 1.6rem;
  height: 1.6rem;
  margin-left: 1.2rem;
}

.primary {
  background-color: var(--primary);
}
