.automatedRuleManager {
  margin: 0 auto;
  width: 100%;
  max-width: 69rem;
}

.automatedRuleManager > div {
  margin-bottom: 3.7rem;
}

.buttonContainer {
  display: flex;
  column-gap: 1.1rem;
}

.buttonCancel {
  background-color: var(--white);
  color: #726f95;
  border: 1px solid rgba(114, 111, 149, 0.9);
}

.buttonCancel:hover {
  background-color: #726f95e6;
  color: var(--white);
}

.automatedRuleManager .automatedRuleWarning {
    margin-top: -2.1rem;
    margin-bottom: 1.6rem;
}

.automatedRuleWarning p {
  color: #f00;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}
