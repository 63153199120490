.collapseContainer {
  margin-bottom: 1.2rem;
}

.collapseItem {
  font-size: 1.7rem;
  color: #726f95;
  font-weight: 400;
  line-height: normal;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2.2rem 2rem;
  background-color: #f7f7fe;
  border-radius: 6px;
  cursor: pointer;
}

.iconExpand {
  padding: 0.2rem;
  cursor: pointer;
  transform: rotate(90deg);
  transition: transform 0.25s ease;
}

.iconExpand.expanded {
  transform: rotate(0deg);
}

.contentParent {
  transition: height 0.25s ease;
  overflow: hidden;
}

.contentParent > div {
  margin: 8px;
}
