.main {
  padding-top: 14rem;
}

.wrapper {
  padding: 0 3rem;
}

.container {
  width: 55%;
}

.containerPaiment {
  width: 44%;
}

.content {
  position: relative;
  padding: 3rem;
  padding: 2rem 2rem 3.3rem;
  background: var(--white);
  border-radius: 8px;
}

.wrapPaymant {
  display: flex;
  flex-direction: column;
  margin: 0 0 0 2rem !important;
  justify-content: space-between;
  align-items: flex-start !important;
}

.line {
  display: flex;
  align-items: center;
  width: 100%;
}

.item {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 50;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: #4a485e;
  border-right: 2px solid #27ae60;
  text-align: center;
  margin-top: .3rem;
}

.item:last-child {
  border-right: none;
}

.center {
  padding: 3.7rem 2rem;
}

.df {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4rem;
}

.title {
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 2.8rem;
  color: var(--black);
  margin-bottom: 3rem;
}

.questionMark {
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 500;
  border: 1px solid #726f95;
  border-radius: 50%;
  color: #726f95;
  min-width: 1.7rem;
  max-height: 1.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 .5rem;
}

.subTitle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 2.8rem;
  color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 .5rem 0 0;
  white-space: nowrap;
}

.date {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: #1473e6;
  white-space: nowrap;
}

.text {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2rem;
  color: var(--gray6);
  margin-bottom: 2rem;
}

.infoWrap {
  display: flex;
  justify-content: space-between;
}

.info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.3rem;
  box-sizing: border-box;
  width: 32%;
  height: 6.4rem;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.9rem;
  margin: 4rem 0 0 0;
}

.used {
  background: rgba(242, 153, 74, 0.1);
  border: 1px solid #f2994a;
  border-radius: 10px;
  color: #f2994a;
}

.usage {
  background: rgba(20, 115, 230, 0.1);
  border: 1px solid #1473e6;
  border-radius: 10px;
  color: #1473e6;
}

.plan {
  background: rgba(39, 174, 96, 0.1);
  border: 1px solid #27ae60;
  border-radius: 10px;
  color: #27ae60;
}

.infoText,
.paymentTitle,
.paymentText,
.infoUsageText {
  font-size: 1.6rem;
  line-height: 2rem;
  color: var(--black2);
  margin-bottom: 2rem;
}

.infoTitle:last-child,
.infoText:last-child {
  margin-bottom: 0;
}

.infoText,
.paymentText {
  color: var(--gray2);
}

.infoUsageText {
  font-weight: 400;
  margin-bottom: 0;
}

.paymentTitle {
  color: var(--primary2);
  margin-bottom: .8rem;
  white-space: nowrap;
}

.paymentText {
  margin-bottom: 1.4rem;
}

.billingInfo {
  position: absolute;
  bottom: 2rem;
  right: 1rem;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: var(--gray2);
}

.payment {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--gray);
  border-radius: 8px;
  padding: 3.7rem 2rem;
}

.imgsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.imgsContainer img {
  max-width: 80%;
}

.tooltip {
  width: 69.1rem;
  top: 5.5rem;
  left: 38rem;
  position: absolute;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.9rem;
  background: white;
  color: #726f95;
  z-index: 5;
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  padding: 2.3rem;
  box-sizing: border-box;
  white-space: normal;
}

.wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4rem;
}

.card_paymentTitle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: #1473e6;
  margin: 0 0 1rem 0;
}

.card_infoText {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.1rem;
  color: #726f95;
}

.card__error {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 14px;
  margin: 1rem 0 0 0;
  color: #cc0001;
}

.image {
  width: 10rem;
  object-fit: contain;
}

.loader {
  width: 5rem;
  height: 5rem;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

.containerForLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.usageWarningText {
  position: absolute;
  margin-top: .8rem;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}

.containerPaymentActions {
  display: flex;
  flex-direction: column;
  row-gap: .8rem;
}

.text {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: var(--gray2);
  margin-bottom: 3.4rem;
}

@media (max-width: 1200px) {
  .subTitle {
    font-size: 2rem;
  }

  .date {
    font-size: 1.4rem;
  }
}

@media (max-width: 1290px) {
  .df {
    flex-direction: column;
  }

  .container {
    margin-bottom: 3.6rem;
  }

  .container {
    width: 100%;
  }

  .containerPaiment {
    width: 100%;
  }

  .imgsContainer {
    width: 8rem;
  }
}

@media (max-width: 1290px) {
  .tooltip {
    width: 55%;
    left: 36rem;
  }
}

@media (max-width: 1440px) {
  .wrapPaymant {
    align-items: flex-start;
  }
}

@media (max-width: 1570px) {
  .subTitle {
    font-size: 2rem;
  }
}

@media (max-width: 1640px) {
  .imgsContainer {
    width: 7rem;
    column-gap: 1rem;
  }

  .center {
    padding: 3.8rem 2rem  
  }
}

@media (max-width: 1660px) {
  .subTitle {
    font-size: 2.2rem;
  }

  .infoTitle {
    font-size: 1.4rem;
    white-space: nowrap;
  }

  .info div:nth-child(2) {
    font-size: 1.3rem;
    white-space: nowrap;
  }
}

@media (max-width: 1750px) {
  .info {
    font-size: 14px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
