.footerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
  width: 100%;
  padding: 0 1rem 0 1rem;
}

.leftFooter input {
  position: static;
  margin-right: 0.8rem;
}

.leftFooter > div {
  display: flex;
  align-items: end;
}

.leftFooter > div > span {
  color: #726f95;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

.rightFooter {
  display: flex;
  column-gap: 0.8rem;
}

.actionBtn {
  font-size: 1.6rem;
  padding-left: 5.6rem;
  padding-right: 5.6rem;
  height: 6rem;
}

.nameColumn > span {
  font-size: 1.3rem;
  color: #726f95;
  font-weight: 500;
}

.nameColumn {
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
}

.nameColumn > input {
  border: 1px solid #1473e6;
  border-radius: 6px;
  background-color: white;
  padding: .7rem .8rem;
  width: 17.6rem;
  font-size: 1.2rem;
}

.errorText {
  position: relative;
  color: red !important;
}

.metric {
  font-size: 1.4rem !important;
  margin-bottom: .8rem;
  display: inline-block;
}