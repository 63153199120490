.textarea {
  width: 100%;
  height: 7rem;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.9rem;
  border: 1px solid var(--gray);
  border-radius: 6px;
  outline: none;
  color: var(--gray2) !important;
}

.textarea::placeholder {
  font-size: 1.6rem;
  line-height: 2.9rem;
  color: var(--gray);
}

.placeholder::placeholder {
  color: #726f95 !important;
  font-weight: 400 !important;
}

/* Responsive */
@media (max-width: 1400px) {
  .textarea {
    height: 60px;
  }
}
