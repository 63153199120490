.wrapperBodyRuleAction > div:not(:last-child) {
  margin-bottom: 2.4rem;
}

.labelInput {
  font-size: 1.8rem;
  line-height: 2.1rem;
  margin-bottom: 1.6rem;
  color: var(--black2);
}

.selectConditionContainer {
  position: relative;
}

.valueSelect {
  font-size: 14.4px;
  color: var(--gray2);
  padding-left: 16px;
}

.selectCondition .modal__input-container{
  display: none;
}

.selectCondition input{
  width: 0 !important;
  min-width: 0 !important;
}