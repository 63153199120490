.selectedWrapper {
    position: absolute;
    background-color: white;
    top: 0;
    border-radius: 12px;
    width: 26rem;
    height: 100%;
    padding: 2.5rem 1.7rem 6rem 2.5rem;
    font-size: 2rem;
    overflow: hidden;
    z-index: 10;
}

.selectedWrapper > h4 {
    margin-bottom: 1.6rem;
}

.columnContainer {
    margin-top: 1.6rem;
    max-height: 40rem;
    overflow: auto;
}

.selectedColumnsContainer{
    display: flex;
    flex-direction: column;
    row-gap: 1.2rem;
    overflow: auto;
    max-height: 100%;
}

.selectedColumn {
    width: 90%;
    display: flex;
    justify-content: space-between;
    padding: .4rem 0;
}

.selectedColumn > span {
    color: #726F95;
    font-size: 1.6rem;
    font-weight: 200;
}

.selectedColumn > div {
    cursor: pointer;
    width: 1.6rem;
    height: 1.6rem;
}

.filteredClients {
    left: 101%;
}

.filteredMetrics {
    left: calc(102% + 26rem);
}