.table {
  padding: 3rem;
  background: var(--white);
  border-radius: 8px;
  margin-top: 20px;
}

.ruleCell {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

.ruleStatus {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  column-gap: 0.8rem;
}

.ruleStatusIcon {
  width: 1.4rem;
  height: 1.4rem;
}

.ruleStatus .dropDownIcon {
  opacity: 0;
  transition: all linear 0.3s;
}

.ruleStatus:hover .dropDownIcon {
  opacity: 1;
}

/* rule style combobox */

.ruleCbbWrapper {
  position: absolute;
  left: 0;
  top: 3rem;
  z-index: 1;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.05);
}

.ruleCbb .ruleCbbItem:first-child {
  padding-top: 1.2rem;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.ruleCbb .ruleCbbItem:last-child {
  padding-bottom: 1rem;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.ruleCbbItem {
  display: flex;
  column-gap: 1rem;
  padding: 0.5rem 1.6rem;
  line-height: normal;
}

.ruleCbbItem:hover {
  background-color: #f4f7ff;
}

.ruleCbbItem div {
  width: 1.3rem;
  height: 1.3rem;
}

.ruleCbbItem img {
  width: 100%;
  height: 100%;
}

.ruleCbb {
  background-color: white;
  border: 1px solid #f0effe;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
}
