.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(244, 247, 254, 0.7);
  z-index: 3;
}

.wrapper {
  display: flex;
  position: absolute;
  top: 22.4rem;
  right: 8rem;
  width: 100rem;
  height: 50rem;
  background: var(--stroke3);
  box-shadow: 0px 10px 37px rgba(0, 14, 51, 0.11);
  border-radius: 10px;
  z-index: 5;
}

.datesOptions {
  width: 15rem;
  padding: 2.4rem;
}

.datesOption {
  padding: .8rem;
  margin-bottom: .6rem;
  border-radius: 4px;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: var(--gray2);
  cursor: pointer;
  text-align: center;
}

.datesOption:hover {
  background: var(--datesOption);
  color: var(--primary);
}

.monthView {
  width: 42.5% !important;
  background: var(--stroke2) !important;
  border-color: var(--stroke4) !important;
  padding: 2.2rem;
}

.monthView > div:nth-child(1) {
  margin: 0 !important;
}

.monthView > div:nth-child(1) button:nth-child(3) {
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 3rem;
  color: var(--black3) !important;
  padding: 0 !important;
}

.monthView
  > div:nth-child(2)
  div:nth-child(1)
  div:nth-child(1)
  div:nth-child(1)
  div:nth-child(1)
  abbr {
  text-decoration: none !important;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: var(--black3) !important;
}

.tile {
  border: 1px solid var(--stroke) !important;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 2.2rem;
  padding: 0 !important;
  margin: .2rem !important;
  max-width: 5rem !important;
  height: 4.4rem !important;
  overflow: unset !important;
  background: var(--white) !important;
  box-shadow: 0px 1px 1px rgba(0, 14, 51, 0.05);
  border-radius: 6px;
  color: var(--black3) !important;
}

.selectedTile {
  background: var(--primary) !important;
  color: var(--white) !important;
}

.neighbore {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
  color: rgba(0, 23, 84, 0.15) !important;
}

.btnsWrapper {
  position: absolute;
  bottom: 2.4rem;
  right: 2.4rem;
  display: flex;
}

.disabledTile:disabled {
  opacity: 0.5 !important;
}

.dateOptionActive {
  background: var(--datesOption);
  color: var(--primary);
}