.header {
  position: fixed;
  top: 0;
  left: 30rem;
  width: calc(100vw - 30rem);
  height: 10rem;
  display: grid;
  place-items: center;
  background: var(--white);
  padding-right: 5rem;
  z-index: 10;
  /* min-width: 1060px; */
}

.wrapper {
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left,
.right {
  height: 100%;
  display: flex;
  align-items: center;
}

.left {
  flex-direction: column;
  justify-content: center;
  margin-left: 1.8rem;
}

.right {
  border-right: none;
  border-left: 1px solid var(--gray2);
  padding-left: 3rem;
}

.company {
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: var(--black);
  margin-bottom: .3rem;
  max-width: 140px;
  word-break: break-word;
}

.id {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: var(--black);
}

.nav {
  display: flex;
}

.navWrapper {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.navImg {
  width: 2rem;
  height: 2rem;
}

.helpImg {
  width: 2.2rem;
  height: 2.2rem;
}

.navText {
  font-size: 1.1rem;
  line-height: 1.3rem;
  color: var(--primary);
}

.btnWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.userImg {
  margin-right: 1rem;
}

.userName {
  color: var(--white);
  font-size: 1.6rem;
  line-height: 1.9rem;
}

.amountNotification {
  position: absolute;
  right: 1.5rem;
  top: -.5rem;
  background: #0030c8;
  color: white;
  font-size: 1.1rem;
  width: 1.7rem;
  height: 1.7rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Responsive */ 
@media (max-width: 1400px) {
  .header {
    padding-right: 20px;
    height: 90px;
  }
}