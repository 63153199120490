.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
}

.mainTitle {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: var(--black2);
  margin-bottom: 1rem;
}

.link {
  color: var(--gray2);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.6rem;
  margin-bottom: 1.6rem;
  text-decoration: none;
}

.loader {
  width: 5rem;
  height: 5rem;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.close {
  width: 2rem;
  margin: 0 1rem 0 0;
  cursor: pointer;
}

.containerForLoader {
  position: fixed;
  top: 8.7rem;
  right: 11rem;
  width: 29.8rem;
  max-height: 40rem;
  min-height: 20rem;
  background: white;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0px 4px 60px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;
}

.NotificationWrapper {
  position: fixed;
  top: 8.7rem;
  right: 11rem;
  width: 29.8rem;
  max-height: 40rem;
  min-height: 20rem;
  overflow-y: scroll;
  background: white;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0px 4px 60px 0px rgba(0, 0, 0, 0.08);
  padding: 2rem;
}

.help {
  right: 19rem;
}

.NotificationWrapper::-webkit-scrollbar {
  width: 0.4rem;
}
.NotificationWrapper::-webkit-scrollbar-track {
  background: white;
}
.NotificationWrapper::-webkit-scrollbar-thumb {
  background-color: #c2c1cd;
  border-radius: 20px;
  border: 3px solid #c2c1cd;
}

.closeModalBtn {
  text-align: end;
  padding: 1rem;
  font-size: 2rem;
  color: #726f95;
  cursor: pointer;
}

.title {
  color: #252331;
  padding: 0.5rem;
  font-size: 1.5rem;
  font-weight: 500;
}

.learn_more_link {
  text-decoration: none;
  color: #2156fe;
  font-size: 1.3rem;
  font-weight: 500;
  margin: 1rem 0 0 .6rem;
}

.text {
  color: #726f95;
  font-size: 1.2rem;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0.5rem 0 0.7rem 0.6rem;
}

.item {
  color: #726f95;
  cursor: pointer;
  border-top: 1px solid #dad9e2;
  padding: 1rem 0;
}
