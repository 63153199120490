.wrapperBodyNotifications > div:not(:last-child) {
  margin-bottom: 2.4rem;
}

.labelInput {
  font-size: 1.8rem;
  line-height: 2.1rem;
  margin-bottom: 1.6rem;
  color: var(--black2);
}

.tagInputWrapper {
  position: relative;
  cursor: pointer;
}

.tagInputWrapper ul {
  position: absolute;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, .2);
  padding: 4px 0;
  max-height: 20rem;
  overflow-y: auto;
  width: 50%;
}

.tagInputWrapper ul li{
  background-color: white;
  padding: 8px 12px;
  user-select: none;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: var(--gray2);
  font-weight: 400;
}

.tagInputWrapper ul li:hover {
  background-color: #DEEBFF;
}

.cursorPointer {
  cursor: pointer;
}